let isEditMode = window.location.search.match(/editmode=true/)
let componentCss = ""
document.querySelectorAll("link").forEach( tag => {
  let url = tag.getAttribute("href")
  if(url.startsWith("/resources/css/site")){
    componentCss = url
  }
})

import {component} from "duo-kit";
import CookieBanner from "./components/cookie-banner.svelte"
component({component:CookieBanner,tagname:"cookie-banner",href:componentCss,shadow:isEditMode})

var riot;

window.riot = riot = require('riot');

require('riot-kit/lib/slideshow');
require('riot-kit/lib/modal');
require('riot-kit/lib/dropdown');
require('riot-kit/lib/gmap');
require('riot-kit/lib/auth/forgot-password');
require('./tags/loginbar.tag');
require('./tags/navigation.tag');
require('./tags/mydetails.tag');
require('./tags/mypassword.tag');
require('./tags/signup.tag');
require('./tags/tags-input.tag');
require('./tags/comments_input.tag');
require('./tags/comments_output.tag');
require('./tags/recaptcha.tag');
require('./tags/twitter.tag');
require('./libs/slideshow');

riot.mount('*');

document.addEventListener('duocms:updated', function() {
  return riot.mount('gmap');
});